<template>
  <pt-trust-order-detail class="pt-trust-order-detail__withData" :id="id" :data="data" :hasTask="hasTask" @reload="getBillDetail" @polling-reload="pollingReload"></pt-trust-order-detail>
</template>

<script>
import PtTrustOrderDetail from './index'
export default {
  name: 'pt-trust-order-detail__withData',
  components: {
    'pt-trust-order-detail': PtTrustOrderDetail
  },
  props:{
    id:String
  },
  data (){
    return{
      data:{},
      pollingTimer:null,
      hasTask:false
    }
  },
  methods:{
    pollingReload (){
      this.$store.dispatch('getBillDetail', this.$route.params.id).then((data) => {
        this.data = data
        if(data.nextBillAction !== 'BILL_CHECKED'){
          this.clearTimer()
          return
        }
        this.pollingTimer = setTimeout(() => {
          clearTimeout(this.pollingTimer)
          this.pollingReload()
        }, 500)
      }).catch((error) => {
        console.error(error)
        this.$toast.error(error)
      })
    },
    getBillDetail (){
      this.$loading()
      this.$store.dispatch('getBillDetail', this.id).then(res => {
        this.data = res
        if(res.nextBillAction && res.futureTasks?.find(e => e.billActionData?.action === res.nextBillAction)){
          this.showDialog()
        }
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        this.$toast.error(err || '获取委托单详情失败')
      })
    },
    clearTimer () {
      if (this.pollingTimer) clearTimeout(this.pollingTimer)
    },
    showDialog(){
      this.hasTask = true
      this.$createDialog({
        type: 'alert',
        icon: 'cubeic-alert',
        confirmBtn: {
          text: '我知道了',
          active: true
        },
        content: '该委托单已进入待处理状态，请前往电脑端进行相关操作',
      }).show()
    }
  },
  created (){
    this.getBillDetail()
  },
  destoryed () {
    clearTimeout(this.pollingTimer)
  }

}
</script>

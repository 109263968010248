<template>
  <pt-trust-order-detail class="pt-trust-order-detail__page" :id="id"></pt-trust-order-detail>
</template>

<script>
import PtTrustOrderDetail from 'cps//pt-trust-order-detail/withData'
export default {
  name: 'pt-trust-order-detail__view',
  components: {
    'pt-trust-order-detail': PtTrustOrderDetail
  },
  props:{
    id:String
  }
}
</script>

<template>
  <div :class="$style['content']">
    <div>
      <span>委托单编号：</span>
      <p>{{order.billCode || '--'}}</p>
    </div>
    <div>
      <span>检测项目：</span>
      <p>{{order.labExamItemList && order.labExamItemList.map(e=>e.itemName).toString()}}</p>
    </div>
    <!-- <div>
      <span>检测依据：</span>
      <p>{{order.examBasisList&&order.examBasisList.toString() || "--"}}</p>
    </div> -->
    <!-- <div>
      <span>是否有偏离：</span>
      <p>
        {{order.deviate_text}}
        {{order.deviateInfo}}
      </p>
    </div> -->
    <div>
      <span>交付方式：</span>
      <p>{{order.delivery_type || "--"}}</p>
    </div>
    <div>
      <span>报告份数：</span>
      <p>{{order.reportNum}}</p>
    </div>
    <div>
      <span>是否返还：</span>
      <p>{{order.back_text}}</p>
    </div>
    <div>
      <span>是否加急：</span>
      <p>
        <status-tag  v-if="order.urgent" class="status-tag" background-color="#FF2D2D" ></status-tag>
        <template v-else>否</template>
      </p>
    </div>
    <div>
      <span>加急费：</span>
      <p>{{order.urgentMoney || "--"}}</p>
    </div>
    <div>
      <span>合计金额：</span>
      <p>{{order.billAmount || "--"}}</p>
    </div>
    <div>
      <span>备注：</span>
      <p>{{order.remarks || "--"}}</p>
    </div>
    <!-- <div>
      <span>相关文献：</span>
      <p>xx</p>
    </div> -->
    <div>
      <span>结算：</span>
      <p>
        <template v-if="order.settlement">
          <span style="color:#6D6D6D; margin-right:20px;">已结算</span>
          <span style="color:#6D6D6D; margin-right:20px;">{{order.otherInfo.billSettler}}</span>
          <span style="color:#6D6D6D;">{{order.otherInfo.bill_settler_date}}</span>
        </template>
        <template v-else>未结算</template>
      </p>
    </div>
    <div>
      <span>发票：</span>
      <p class="invoice-view">
        <template v-if="order.invoice">
					<template  v-for="(item,i) in INVOICE_PICTURE_attachments">
          <a :href="$privateResourcesUrl+item.ossUrl" :key="i" :class="$style.mark">
						<span style="color:#409eff;border-bottom:1px solid #409eff">查看发票</span></a>
					</template>
          <!-- <span style="color:#6D6D6D; margin-right:20px;">{{order.otherInfo.billDrawer}}</span>
          <span style="color:#6D6D6D;">{{order.otherInfo.bill_drawer_date}}</span> -->
        </template>
        <template v-else>未开票</template>
      </p>
    </div>
		<template v-if="order.invoice">
		<div>
			<span>开票人：</span>
      <p>{{order.otherInfo&&order.otherInfo.billDrawer || "--"}}</p>
		</div>
		<div>
			<span>开票时间：</span>
      <p>{{order.otherInfo&&order.otherInfo.bill_drawer_date || "--"}}</p>
		</div>
		</template>
    <div>
      <span>已催缴：</span>
      <p><span :class="$style['mark']">{{order.callCount}}</span>次</p>
    </div>
    <div>
      <span>状态：</span>
      <p :class="$style['mark']" class="status-file">
        {{order.billStatusEnum_text}}
        <!-- <a v-if="BILL_PDF" :href="$privateResourcesUrl+BILL_PDF.ossUrl" target="_blank" :class="$style.mark">{{BILL_PDF.name}}</a> -->
				<template>
				<a v-if="order.reportOssUrl" :href="$privateResourcesUrl+order.reportOssUrl" :class="$style.mark">
					<span style="color:#409eff;border-bottom:1px solid #409eff">已审核委托单.PDF</span>
				</a>
				<a v-if="order.reportAttachmentOssUrl" :href="$privateResourcesUrl+order.reportAttachmentOssUrl" :class="$style.mark">
					<span style="color:#409eff;border-bottom:1px solid #409eff">已审核委托单(附录).PDF</span>
				</a>
				</template>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "base-info",
  props: {
    order:{
      type:Object,
      default:() => {}
    }
  },
  computed:{
    INVOICE_PICTURE_attachments () {
      return this.order?.attachments?.filter(e => e.type === 'INVOICE_PICTURE')
    },
    // BILL_PDF () {
    //   return this.order?.attachments?.find(e => e.type === 'BILL_PDF_BACK')
		// },
		hasPDF(){
			return this.order.nextBillAction==='BILL_BACK'
		}
  },
  data () {
    return {
     
    };
  },
  watch: {
   
  },
  
  methods: {
    
  }
};
</script>
<style module lang="less">
@import "~less/var.less";
.content{
    padding: 15px;
    margin-top: 15px;
    background-color: #fff;
    text-align: left;
    > div{
      display: flex;
      line-height: 0.8rem;
      > span{
        width: 2.7rem;
        color: @label-color;
        text-align: left;
        white-space: nowrap;
      }
      >p{
        flex:1;
      }
      :global{
				.invoice-view{
					display:grid
				}
        .status-tag{
          line-height: 15px;
          width: fit-content;
				}
				.status-file{
					display:grid
				}
      }
      .mark{
        color: @main-color;
        margin-right: 3px;
      }
    }
  }
</style>

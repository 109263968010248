<template>
  <div :class="$style[classes]">
    <detail-header :order="data" class="detail-header"></detail-header>
		<div class="detail-content">
      <tab-bar :options="tabOptions" v-model="type" class="tab-bar"></tab-bar>
      <sample-list v-if="type==='sample-list'" :billId="id" :selectSample="selectSample" :allSelected="allSelected" @getSelected="getSelected"></sample-list>
      <base-info v-else  :order="data" class="base-info"></base-info>
		</div>
    <div :class="$style.fixedBox">
      <status-operation :selectOrder="selectOrder" :order="data" :billStatus="billStatus" @distributeTask="distributeTask" @selectAllSample="selectAllSample" :type="type" @reload="reload" @polling-reload="pollingReload" :hasTask="hasTask"></status-operation>
    </div>
  </div>
</template>

<script>
// 组件class前缀
const prefixCls = 'pt-trust-order-detail'
import sampleList from "cps/pt-sample-list/withData"
import baseInfo from "./base-info.vue"
import statusOperation from "./status-operation.vue"
import {tabOptions} from "cps/pt-trust-order/options"
export default {
  name: 'pt-trust-order-detail',
  computed: {
    classes () {
      return `${prefixCls}`
    },
    billStatus (){
      return this.data.billStatusEnum
    }
  },
  components:{
    sampleList,
    baseInfo,
    statusOperation
  },
  props:{
    id:String,
    data:{
      type:Object,
      default:function (){
        return {}
      }
    },
    hasTask:Boolean
  },
  watch:{
    type (val){
      if(val === 'trust-detail'){
        this.selectSample = false
      }
    }
  },
  data (){
    return{
      type:'trust-detail',
      tabOptions:tabOptions,
      selectSample:false,
      allSelected:false,
      selectOrder:[]
    }
  },

  methods:{
    
    getSelected (val){
      console.log('getSelected', val)
      this.selectOrder=val
    },
    selectAllSample (val){
      this.allSelected = val
    },
    distributeTask (){
      this.selectSample = true
      this.type = 'sample-list'
      this.$set(this, 'type', 'sample-list')
    },
    reload(){
      this.$emit('reload')
    },
    pollingReload(){
      this.$emit('polling-reload')
    },
  },
  created () {
  }
}
</script>

<style module lang="less">
@import "~less/var.less";
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
.pt-trust-order-detail{
	height: 100%;
	display: flex;
	flex-direction: column;
	:global{
		.detail-header{
			display: flex;
			flex-direction: column
		}
		.detail-content{
			padding:30px @page-padding 0;
			display: flex;
			flex: 1;
			flex-direction:column;
			overflow-y:hidden;
			padding-bottom:60px;
			.base-info{
				display:flex;
				flex-direction:column;
				overflow:auto
			}
		}
	}
  .fixedBox{
    position: fixed;
    left: 0;
    bottom: 52px;
    width:100%;
    height: 48px;
    padding:0 15px;
    z-index: 50;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    box-sizing: border-box;
  }
}
</style>

<template>
  <div :class="$style['status-operation']">
				<cube-checkbox v-model="allSelect" v-if="isDistributeTask">
					全选
				</cube-checkbox>
			<template v-else>
    <span @click="$refs.tip.show()">更多</span>
    <cube-tip ref="tip" direction="bottom" offsetLeft="30px" :class="$style.tip">
      <div :class="$style.otherBtn">
        <span @click="openCallDialog">提醒缴费</span>
        <span @click="handleSettle" v-if="showSettlementButton">确认已结算</span>
        <span @click="handleInvoice" v-if="showInvoiceButton">确认已开票</span>
        <!-- <span :class="!HAS_PERM_SETTLEMENT && $style['span-disabled']" click="handleSettle">确认已结算</span>
        <span :class="!HAS_PERM_INVOICE && $style['span-disabled']" @click="handleInvoice">确认已开票</span> -->
      </div>
      
    </cube-tip>
		</template>
    <div :class="$style.btns">
      
			<template v-if="!isDistributeTask">
        <template v-if="!hasTask">
          <!-- 待审核 -->
          <template v-if="status === 'BILL_CHECKED'">
            <wt-button outline danger @click="handleOperation(`/bill-checked-reject/${order.id}`)">驳回</wt-button>
            <wt-button primary @click="billChecked">审核通过</wt-button>
          </template>
          <!-- 待回传 -->
          <template v-if="status === 'BILL_BACK'">
            <wt-button primary @click="handleOperation(`/bill-back/${order.id}`)">确认已回传</wt-button>
          </template>
          <!-- 待接收 -->
          <template v-if="status === 'BILL_RECEIVE'">
            <wt-button outline danger @click="handleOperation(`/bill-receive/false/${order.id}`)">样品异常</wt-button>
            <wt-button primary @click="handleOperation(`/bill-receive/true/${order.id}`)">确认已接收</wt-button>
          </template>
          <!-- 待分派 & 执行中  & 待交付-->
          <wt-button v-if="showDelay" primary @click="handleOperation(`/bill-delay/${order.id}?start_date=${order.start_date}&delivery_date=${order.delivery_date}`)">延期交付</wt-button>
          <!-- 待交付状态下：确认已处置、确认已交付 -->
          <template v-if="status==='BILL_DELIVER'">
            <wt-button primary v-if="order.back" :disabled="order.isHandle" @click="handle">确认已处置</wt-button>

            <wt-button primary :disabled="order.back && !order.isHandle" @click="over">确认已交付</wt-button>
          </template>
        </template>

        <wt-button primary v-if="showDistributeButton" @click="distributeTask">分配任务</wt-button>
			</template>
			<!--分派操作-->
			<template v-if="isDistributeTask">
			  <wt-button primary @click="handleDistribute">新建任务单</wt-button>
			</template>
      
    </div>
  </div>
</template>
<script>
import {nextTrust} from '../trust-order/status'
import {hasePermissionsOfInvoice,hasePermissionsOfSettlement} from "@/utils/permissions"
export default {
  name: "status-operation",
  props: {
    billStatus:String,
    type:String,
    order:Object,
    selectOrder:Array,
    hasTask:Boolean
  },
  data () {
    return {
      isDistributeTask:false,
			allSelect:false,
			hasInvoicePermission:false,
			hasSettlementPermission:false,
    };
  },
  watch: {
    allSelect (val){
      this.$emit('selectAllSample', val)
    },
    type (val){ //切换状态后，操作状态复位
      if(val === 'trust-detail'){
        this.isDistributeTask = false
        this.allSelect = false
      }
		},
		'$store.state.currentUserMessage':{
			handler:function (val){
				if(val.permissions){
					this.hasInvoicePermission=hasePermissionsOfInvoice(val.permissions)
					this.hasSettlementPermission=hasePermissionsOfSettlement(val.permissions)
				}
			},
			immediate:true,
			deep:true
		}
  },
  computed:{
    status(){
      return this.order?.nextBillAction
    },
		 /*展示分派任务按钮。在审核和回传后，访客分派任务*/
		 showDistributeButton (){
      const canDistribute = ['BILL_RECEIVE','BILL_ASSIGN','BILL_EXECUTE']
			 return canDistribute.includes(this.status)
		 },
     showDelay () {
      const res = ['BILL_ASSIGN', 'BILL_EXECUTE', 'BILL_DELIVER']
      return res.includes(this.status)
		},
    currentUserMessage () {
      return this.$store.state.currentUserMessage || {};
    },
		//是否显示结算按钮
		showSettlementButton(){
			return !this.order.settlement&&this.hasSettlementPermission
		},
		//是否显示确认开票按钮
		showInvoiceButton(){
			return !this.order.invoice&&this.hasInvoicePermission
		}
  },
  components: {
  },
  methods: {
    distributeTask (){
      this.isDistributeTask = true
      this.$emit('distributeTask')
    },
    handleOperation(path){
      this.$router.push(path)
    },
    billChecked(){
      let data = {
        billId: this.order.id,
        action: this.status,
        billStatusDetail:{
          action: this.status,
          billStatusResult:'APPROVED'
        }
      }
      this.billStatusUpdate(data,true)
    },
    handle(){
      let data = {
        billId: this.order.id,
        action: 'BILL_DELIVER',
        billStatusDetail:{
          action: 'BILL_DELIVER',
          billStatusResult:'BILL_HANDLE',
        }
      }
      this.billStatusUpdate(data)
    },
    over(){
     let data = {
        billId: this.order.id,
        action: 'BILL_DELIVER',
        billStatusDetail:{
          action: 'BILL_DELIVER',
          billStatusResult:'BILL_DELIVERY',
        }
      }
      this.billStatusUpdate(data)
    },
    billStatusUpdate(data,needPolling=false){
      this.$loading()
      nextTrust.call(this,data).then(response => {
        if(needPolling){
          this.$emit('polling-reload')
        }else{
          this.$emit('reload')
        }
      }).catch(err => {
        this.$toast.error(err)
      }).finally(()=>{
        this.$loading.hide()
      })
    },
    // 分派任务
    handleDistribute () {
      if (!this.selectOrder.length) {
        this.$toast.error('请先勾选样品')
        return
      }
      this.$loading()
      let data = {
        billId: this.order.id,
        orderIds: this.selectOrder
      }
      this.$store.dispatch('assginPre', data).then(data => {
        this.$router.push(`/distribute-task/${data.billId}`)
      }).catch(err => {
        this.$toast.error(err || '操作失败')
      }).finally(() => {
        this.$loading.hide()
      })
    },
    handleSettle () {
      this.$confirm(`该委托单是否“确认已结算”?`).then(() => {
        this.$loading()
        let data = {
          ids: [this.order.id]
        }
        this.$store.dispatch('handleSettle', data).then(res => {
          this.$toast.success('操作成功')
          this.$emit('reload')
        }).catch(err => {
          this.$toast.error(err || '操作失败')
        }).finally(()=>{
          this.$loading.hide()
        })
      })
    },
    handleInvoice(){
			const {title,taxId} = this.order.organization.invoice
			let invoiceContent=this.order.invoiceContent
      this.$router.push(`/invoice/${this.order.id}?title=${title}&taxId=${taxId}&invoiceContent=${invoiceContent || '--'}`)
    },
    handleCallNotice(){
      this.$loading()
      let data = {
        id: this.order.id,
      }
      this.$store.dispatch('callNotice', data).then(res => {
        this.$toast.success('催缴成功')
        this.$emit('reload')
      }).catch(err => {
        this.$toast.error(err || '操作失败')
      }).finally(()=>{
        this.$loading.hide()
      })
    },
    openCallDialog(){
      this.$createDialog({
        type: 'confirm',
        title: '发送短信提醒客户缴费',
        content: '【理化中心材料化学部】您送检的样品已完成检测，确认付款后将为您发送检测结果。纸版报告如随发票快递，需到付。客服联系电话（010）58717279，（010）58711815',
        confirmBtn: {
          text: '确认',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        cancelBtn: {
          text: '取消',
          active: false,
          disabled: false,
          href: 'javascript:;'
        },
        onConfirm: () => {
          this.handleCallNotice()
        },
        onCancel: () => {
          this.$createToast({
            type: 'warn',
            time: 1000,
            txt: '取消提醒'
          }).show()
        }
      }).show()
    }
  }
};
</script>
<style module lang="less">
@import "~less/var.less";
.status-operation{
  height: 100%;
  display: flex;
  flex:1;
  justify-content: space-between;
	align-items: center;
	:global{
		.cube-checkbox{
			padding:0;
		}
    .cube-btn{
      width: auto;
    }
	}
  .tip{
      left:0;
      bottom: 46px;
    }
    .otherBtn{
      display: flex;
      flex-direction: column;
      line-height: 30px;
      span{
        border-bottom: 1px solid #DEDEDE;
        &:last-child{
          border-bottom: none;
        }
      }
    }
    >.btns{
      display: flex;
      text-align: right;
      :global{
        .cube-btn{
          padding: 8px 10px;
          margin-right: 10px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
    :global{
      .cube-tip{
        background: #fff;
        color:#333;
        box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.08);
        max-height: fit-content;
        .cube-tip-angle::before{
          border-color: transparent transparent #f0f0f0;
          // box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.08);
        }
      }
    }
  }
</style>
